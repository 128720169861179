import React, { useContext, useState, useEffect } from "react";
import { IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import firebase from "../../firebase/firebase";
import MenuIcon from "@material-ui/icons/Menu";
import GlobalContext from "../../hooks/GlobalContext";

export default function HamburgerMenu(props) {
    const { navigation, mainCtx, authCtx } = useContext(GlobalContext);
    const { officeId } = mainCtx;
    const { user } = authCtx;
    const [anchorEl, setAnchorEl] = useState(null);
    const officesLinkVisible = Boolean(user);
    const signOutLinkVisible = Boolean(user);

    return (
        <React.Fragment>
            <Tooltip title="Navigation">
                <IconButton
                    size="small"
                    onClick={e => {
                        setAnchorEl(e.currentTarget);
                    }}
                >
                    <MenuIcon style={{ color: "white" }} />
                </IconButton>
            </Tooltip>
            <Menu
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => {
                    setAnchorEl(null);
                }}
            >
                {officesLinkVisible && (
                    <MenuItem
                        onClick={() => {
                            navigation.toOfficeSelect(mainCtx.officeId);
                            setAnchorEl(null);
                        }}
                    >
                        Office List
                    </MenuItem>
                )}

                {signOutLinkVisible && (
                    <MenuItem
                        onClick={() => {
                            firebase.auth().signOut();
                            mainCtx.setOfficeId(null);
                            navigation.toSignIn();
                            setAnchorEl(null);
                        }}
                    >
                        Sign Out
                    </MenuItem>
                )}
            </Menu>
        </React.Fragment>
    );
}
