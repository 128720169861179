import { createContext, useState } from "react";

export default function useNavigation({ mainCtx, mainRouter }) {
  const toOfficeSelect = () => {
    mainRouter.push("/offices");
  };

  const toPatientSignIn = officeId => {
    mainRouter.push("/patientsignin");
  };

  const toSignIn = () => {
    mainCtx.setOfficeId(null);

    mainRouter.push("/signin");
  };

  return {
    toOfficeSelect,
    toPatientSignIn,
    toSignIn
  };
}
