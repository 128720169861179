import React from "react";
import { CardContent, CardHeader, Card, Grid } from "@material-ui/core";
import OfficesList from "./OfficesList";

export default function(props) {
    return (
        <Grid
            container
            align="center"
            justify="center"
            style={{ marginTop: "10px" }}
        >
            <Grid item xs={12} md={6} lg={6}>
                <Card align="center">
                    <CardHeader title="Offices" />
                    <CardContent>
                        <OfficesList />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
