import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

const insyncColors = ["#0276fd", "#97cc04", "#ebebeb", "#f4d01", "#8f9491"];

export const theme = createMuiTheme({
    palette: {
        primary: {
            light: "#757ce8",
            main: insyncColors[0],
            dark: "#002884",
            contrastText: "#fff"
        }
        // secondary: {
        //     light: "#757ce8",
        //     main: insyncColors[3],
        //     dark: "#002884",
        //     contrastText: "#fff"
        // }
    }
});
