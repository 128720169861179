import firebase from "firebase/app";
import "firebase/firebase-functions";
import "firebase/firebase-auth";
import "firebase/firebase-firestore";
import "firebase/storage";

var configs = {
    dev: {
        apiKey: "AIzaSyB1LmaL9TvkLNtb-lD4_5NqpT91sv_YKAM",
        authDomain: "fir-test-62d25.firebaseapp.com",
        databaseURL: "https://fir-test-62d25.firebaseio.com",
        projectId: "fir-test-62d25",
        messagingSenderId: "761730117141",
        storageBucket: "gs://fir-test-62d25.appspot.com"
    },
    prod: {
        apiKey: "AIzaSyCuxwzN2nOKWsTxTxg2iAt4YwjyUx64ri4",
        authDomain: "insync-fe766.firebaseapp.com",
        databaseURL: "https://insync-fe766.firebaseio.com",
        projectId: "insync-fe766",
        messagingSenderId: "770733575657",
        appId: "1:770733575657:web:8e0395fd2ef634e4",
        storageBucket: "gs://fir-test-62d25.appspot.com"
    }
};

// eslint-disable-next-line
const mode = process.env.REACT_APP_MODE;
const app = firebase.initializeApp(configs[mode]);

// app.functions().useFunctionsEmulator("http://localhost:5000");

export default firebase;

window.firebase = firebase;
