import { createContext, useState } from "react";

export const mainContext = createContext({
    officeId: null,
    setOfficeId: () => {}
});

export default function useMainContext() {
    const [officeId, setOfficeId] = useState(null);

    return { officeId, setOfficeId };
}
