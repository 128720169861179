import { createContext, useState } from "react";
import mainRouterHistory from "../routers/mainRouterHistory";

export const mainRouterContext = createContext({
    push: () => {},
    pathName: ""
});

export default function useMainRouter() {
    const [pathName, setPathName] = useState(
        mainRouterHistory.location.pathname
    );
    const push = newPath => {
        setPathName(newPath);
        mainRouterHistory.push(newPath);
    };
    return { pathName, push };
}
